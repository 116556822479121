<template>
  <div class="flex items-center justify-center bg-gray-100 h-screen">
    <div class="flex flex-col w-full md:flex-row md:px-4 xl:w-1/2">
      <form
        @submit.prevent="login"
        class="flex flex-col w-full md:w-1/2 bg-white p-5"
      >
        <img src="../assets/images/rwp-logo-invert.png" class="rwp-logo" />
        <input
          class="bg-gray-200 py-3 px-2 mt-2"
          type="text"
          v-model="request.business_code"
          required
          placeholder="Business Code"
        />
        <input
          class="bg-gray-200 py-3 px-2 mt-2"
          type="text"
          required
          v-model="request.username"
          placeholder="Username"
        />
        <div class="relative block">
          <div
            class="absolute right-2 bottom-3 cursor-pointer"
            @click="showPassword = !showPassword"
          >
            {{ showPassword ? "Hide" : "Show" }}
          </div>
          <input
            class="bg-gray-200 py-3 px-2 mt-2 left-0 w-full bottom-2 pr-14"
            required
            :type="showPassword ? 'text' : 'password'"
            v-model="request.password"
            placeholder="Password"
          />
        </div>

        <button type="submit" class="bg-red text-white py-3 mt-2">Login</button>
        <div role="alert" class="my-3" v-if="errorMsg != ''">
          <div
            class="border border-red-400 rounded text-center bg-red-100 text-sm px-4 py-3 text-red-700"
          >
            <p>{{ errorMsg }}.</p>
          </div>
        </div>
      </form>
      <div class="form-side w-1/2 p-4 hidden md:block">
        <div class="text-red-400 text-3xl font-bold lg:text-xl">
          Grow Your Google Reviews
        </div>
        <div class="text-white mt-3 text-xs lg:text-base lg:mt-7">
          Here at ReviewWorxPro.com, we provide small business tools to reach
          your customers in real life (IRL). With this easy to use interface,
          you can send branded text messages straight to your ideal customer's
          phone.
        </div>
        <div class=" font-bold text-xl text-white mt-5 lg:mt-9 lg:text-xl">
          Reach Paying Customers,
        </div>
        <div class="text-red-500 font-bold text-base">
          Grow Your Business!
        </div>
      </div>
    </div>
  </div>
  <LoadingComponent v-if="isSubmitted"> </LoadingComponent>
</template>
<script>
const firebase = require("../firebaseConfig");
import LoadingComponent from "../components/Loading";

export default {
  name: "Login",
  components: { LoadingComponent },
  data: () => ({
    errorMsg: "",
    isSubmitted: false,
    showPassword: false,
    request: {
      business_code: "",
      username: "",
      password: "",
    },
  }),
  methods: {
    async login() {
      this.errorMsg = "";
      this.isSubmitted = true;
      let email = `${this.request.business_code}${this.request.username}@reviewworxpro.com`;
      email = email.replace(/\s/g, "");

      try {
        await firebase.auth.signInWithEmailAndPassword(
          email,
          this.request.password
        );

        this.isSubmitted = false;
        this.$router.push("/dashboard");
      } catch (err) {
        this.isSubmitted = false;
        this.errorMsg = err.message;
      }
    },
  },
};
</script>
<style scoped>
.bg-red {
  background: red;
}
.form-side {
  background: url("../assets/images/background-1.jpg") center center / cover
    no-repeat;
}
.rwp-logo {
  height: 100px;
  width: 350px;
  margin: auto;
  display: block;
}
</style>
