// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/background-1.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bg-red[data-v-26084dc2] {\n  background: red;\n}\n.form-side[data-v-26084dc2] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center / cover\n    no-repeat;\n}\n.rwp-logo[data-v-26084dc2] {\n  height: 100px;\n  width: 350px;\n  margin: auto;\n  display: block;\n}\n", ""]);
// Exports
module.exports = exports;
