import { render } from "./Loading.vue?vue&type=template&id=efe9d588"
import script from "./Loading.vue?vue&type=script&lang=js"
export * from "./Loading.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "efe9d588"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('efe9d588', script)) {
    api.reload('efe9d588', script)
  }
  
  module.hot.accept("./Loading.vue?vue&type=template&id=efe9d588", () => {
    api.rerender('efe9d588', render)
  })

}

script.__file = "src/components/Loading.vue"

export default script